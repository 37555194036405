import { useQuery } from 'react-query';

import { useSkillTaxonomiesSocket } from '@eva/emf/app/entities/skillTaxonomy/hooks/useSkillTaxonomiesSocket';
import { useGenericContext } from 'shared/hooks';

import { fetchSkillTaxonomiesOptions } from '../api';

export const getSkillTaxonomiesOptionsCacheKey = (...args: (string | number)[]) => [...args, 'skillTaxonomyOptions'];

export const useFetchSkillTaxonomyOptions = () => {
  const { socket } = useGenericContext();
  const cacheName = getSkillTaxonomiesOptionsCacheKey();

  useSkillTaxonomiesSocket(socket, cacheName);

  return useQuery({
    queryKey: cacheName,
    queryFn: fetchSkillTaxonomiesOptions,
  });
};
