import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { GenericContext } from 'shared/constants';
import { getCandidateChatTopics, noop, prepareSelector } from 'shared/functions';

import MenuChatSelectJob from 'containers/MessengerLeftMenu/MenuChat/SelectJob';

function TopicsSelector(props) {
  const { setTopicsMode, pipelines, loadingPipelines, delayScrollDown, emitSendMessageAndScroll } = props;

  const { settings } = React.useContext(GenericContext);
  const [showJobs, setShowJobs] = React.useState(false);
  // @ts-expect-error
  const [chatTopics] = React.useState(getCandidateChatTopics(settings.candidateChatTopics));

  return (
    <div>
      {!showJobs && (
        <div
          className="pull-left"
          style={{
            marginTop: '-20px',
          }}
          onClick={() => setTopicsMode(false, delayScrollDown)}
        >
          <i className="ll-icon ll-close" /> Close
        </div>
      )}
      {showJobs && (
        <MenuChatSelectJob
          hideJobs={() => setShowJobs(false)}
          emitSendMessage={(...args) => {
            emitSendMessageAndScroll(...args);
            setTopicsMode(false);
            setShowJobs(false);
          }}
          setCentralContent={noop}
          switchToChat={noop}
          pipelines={pipelines}
          loadingPipelines={loadingPipelines}
        />
      )}
      {!showJobs && (
        <ul
          className="select-messages"
          style={{
            textAlign: 'right',
            marginRight: 0,
          }}
        >
          {chatTopics.map(({ topic, selectJob }) => (
            <li
              className="margin-bottom"
              key={topic}
              onClick={() => {
                if (selectJob) {
                  return setShowJobs(true);
                }

                emitSendMessageAndScroll(topic, null, null, 'webchat');
                setTopicsMode(false, delayScrollDown);
              }}
            >
              <div className="bubble me">
                <div className="bubble-content">
                  <span className="message-content">{topic}</span>
                </div>
              </div>
              <div className="clearfix" />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

TopicsSelector.propTypes = {
  setTopicsMode: PropTypes.func.isRequired,
  delayScrollDown: PropTypes.func.isRequired,
  emitSendMessageAndScroll: PropTypes.func.isRequired,
};

const prepareMessengerSelector = (value) => prepareSelector('candidate', value);
const mapStateToProps = createStructuredSelector({
  pipelines: prepareMessengerSelector('pipelines'),
  loadingPipelines: prepareMessengerSelector('loadingPipelines'),
});

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, () => ({}))(TopicsSelector);
