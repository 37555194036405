import React, { type MouseEvent } from 'react';

import { useGenericContext } from 'shared/hooks';
import { Icon } from '@eva/emf/app/shared/ui/Icon';
import { usePostMyCandidateJobExternalApplicationLog } from '@eva/emf/app/shared/api/cbe';
import { logError } from 'shared/functions';

import { externalApplicationRedirectTypes } from '../constants';

import styles from './ExternalApplicationButton.module.scss';

type ExternalApplicationButtonPropsType = {
  externalApplicationUrl: string;
  jobExternalApplicationId: number;
  pipelineId: number;
};

export const ExternalApplicationButton = ({
  externalApplicationUrl,
  jobExternalApplicationId,
  pipelineId,
}: ExternalApplicationButtonPropsType) => {
  const { settings } = useGenericContext();
  const { mutateAsync } = usePostMyCandidateJobExternalApplicationLog();

  const openNewTab = settings.externalApplications.redirectType === externalApplicationRedirectTypes.openInNewTab;
  const target = openNewTab ? '_blank' : '_self';
  const rel = openNewTab ? 'noopener noreferrer' : '';

  const handleExternalApplicationClick = async (e: MouseEvent<HTMLAnchorElement>) => {
    const clicked = e.type === 'click';
    if (!openNewTab && clicked) {
      e.preventDefault();
    }

    try {
      await mutateAsync({
        data: {
          jobExternalApplicationId,
          pipelineId,
        },
      });
    } catch (error) {
      logError(new Error('Failed to log external application click', { cause: error }) as any);
    }

    if (!openNewTab && clicked) {
      window.location.href = externalApplicationUrl;
    }
  };

  return (
    <a
      onClick={handleExternalApplicationClick}
      onAuxClick={handleExternalApplicationClick}
      className={`btn btn-info ${styles.externalApplicationButton}`}
      href={externalApplicationUrl}
      target={target}
      rel={rel}
    >
      <Icon icon={'link'} className={styles.icon} />
      {translate('Continue Application')}
    </a>
  );
};
