import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

import { GenericContext } from 'shared/constants';

const candidateSections = {
  name: 'name',
  dateOfBirth: 'dateOfBirth',
  nationality: 'nationality',
  phones: 'phones',
  emails: 'emails',
  address: 'address',
  socialMediaLinks: 'socialMediaLinks',
  coverLetter: 'coverLetter',
  nationalInsuranceNumber: 'nationalInsuranceNumber',
  drivingLicense: 'drivingLicense',
  rightToWork: 'rightToWork',
};
const checkExists = (section) => {
  const exists = section?.exists;
  return typeof exists === 'boolean';
};

const getSectionCheckers = (userProfile, features) => {
  const sectionCheckers = {
    [candidateSections.name]: (section) => get(section, 'displayName'),
    [candidateSections.dateOfBirth]: (section) => get(section, 'dateOfBirth'),
    [candidateSections.nationality]: (section) => get(section, 'name'),
    [candidateSections.phones]: (section) => get(section, 'cell'),
    [candidateSections.emails]: (section) => get(section, 'primary'),
    [candidateSections.address]: (section) => get(section, 'displayAddress'),
    [candidateSections.socialMediaLinks]: (socialMediaLinks) =>
      socialMediaLinks &&
      typeof socialMediaLinks === 'object' &&
      Object.values(socialMediaLinks).find((item) => get(item, 'url')),
    [candidateSections.coverLetter]: (section) => get(section, 'coverLetter'),
  };

  if (features.ui.candidateProfile.nationalInsuranceNumber) {
    sectionCheckers[candidateSections.nationalInsuranceNumber] = (nationalInsuranceNumber) =>
      nationalInsuranceNumber && nationalInsuranceNumber.value;
  }
  if (features.ui.candidateProfile.drivingLicense) {
    sectionCheckers[candidateSections.drivingLicense] = checkExists;
  }
  if (features.ui.candidateProfile.rightToWork) {
    sectionCheckers[candidateSections.rightToWork] = checkExists;
  }

  return sectionCheckers;
};

const sectionsGroups = {
  personalInformation: {
    label: 'Personal information',
    sections: [
      candidateSections.name,
      candidateSections.dateOfBirth,
      candidateSections.nationality,
      candidateSections.nationalInsuranceNumber,
      candidateSections.drivingLicense,
      candidateSections.rightToWork,
    ],
  },
  contactInformation: {
    label: 'Contact information',
    sections: [candidateSections.phones, candidateSections.emails, candidateSections.address],
  },
  socialLinks: {
    label: 'Social links',
    sections: [candidateSections.socialMediaLinks],
  },
  coverLetter: {
    label: 'Cover letter',
    sections: [candidateSections.coverLetter],
  },
};

const orderedSectionsGroups = [
  sectionsGroups.personalInformation,
  sectionsGroups.contactInformation,
  sectionsGroups.socialLinks,
  sectionsGroups.coverLetter,
];

const calculateFilled = (sectionCheckers, userProfile, filteredSections) => {
  const filled = filteredSections.filter((filteredSection) =>
    sectionCheckers[filteredSection](userProfile[filteredSection]),
  );

  const allEmpty = filteredSections.every((filteredSection) => !filled.includes(filteredSection));
  let color;
  if (allEmpty) {
    color = '#96ACBF';
  } else if (filteredSections.every((filteredSection) => filled.includes(filteredSection))) {
    color = '#58E2A2';
  } else {
    color = '#F8CB0B';
  }
  return {
    allEmpty,
    color,
  };
};

const collectTotalPercentage = (userProfile, sectionCheckers) => {
  const sectionCheckersKeys = Object.keys(sectionCheckers);
  const filled = sectionCheckersKeys.reduce((prev, cur) => {
    const section = cur.split('_')[0];
    const sectionFilled = sectionCheckers[cur](userProfile[section]) ? 1 : 0;
    return prev + sectionFilled;
  }, 0);
  // @ts-expect-error
  const percent = parseInt((filled / sectionCheckersKeys.length) * 100, 0);
  let color;
  if (percent >= 90) {
    color = '#58E2A2';
  } else if (percent >= 20) {
    color = '#F8CB0B';
  } else {
    color = '#FF4E69';
  }
  return {
    percent,
    color,
  };
};

const getCalculatedTotalAndSections = (userProfile, features) => {
  const sectionCheckers = getSectionCheckers(userProfile, features);
  return {
    totalPercentage: collectTotalPercentage(userProfile, sectionCheckers),
    calculatedSections: orderedSectionsGroups.map(({ sections, label }) => {
      const filteredSections = sections.filter((section) => sectionCheckers[section]);
      return {
        label,
        ...calculateFilled(sectionCheckers, userProfile, filteredSections),
      };
    }),
  };
};

// eslint-disable-next-line import/no-default-export
export default function PreviewMyDetails(props) {
  const {
    settings: { features },
  } = React.useContext(GenericContext);
  const { userProfile } = props;

  const { totalPercentage, calculatedSections } = React.useMemo(
    () => getCalculatedTotalAndSections(userProfile, features),
    [userProfile, features],
  );

  return (
    <div>
      <span
        style={{
          color: totalPercentage.color,
        }}
      >
        <strong>{totalPercentage.percent}%</strong>
      </span>{' '}
      {translate('of fields complete')}:
      {calculatedSections.map(({ label, color, allEmpty }) => (
        <span key={label} className="margin-left">
          <span key={label} style={{ color }}>
            <i className={allEmpty ? 'fa fa-times' : 'fa fa-check'} />
          </span>{' '}
          {translate(label)}
        </span>
      ))}
    </div>
  );
}

PreviewMyDetails.contextTypes = {
  settings: PropTypes.object.isRequired,
};
PreviewMyDetails.propTypes = {
  userProfile: PropTypes.object.isRequired,
};
