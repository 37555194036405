import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import Linkify from 'linkifyjs/react';

import PopperTooltip from '@eva/emf/app/shared/ui/Popper/PopperTooltip';
import { GenericContext, imagesExtensions } from 'shared/constants';
import { getClassNames } from '@eva/emf/app/shared/functions';
import { SpinnerSmall } from '@eva/emf/app/shared/ui/Spinner';

import AIMessage from 'containers/AIUI/AIMessage';

/** @deprecated TODO remove in DEV-25601 skillsSet logic */
import CustomMessage from './CustomMessage';

const dropUp = (messages, index) => (index === messages.length - 1 ? 'dropup' : '');

function MessageText(props) {
  const {
    hideSender,
    message,
    topProps: { bottomMode, isLastMessage, emitSendMessage, delayScrollDown, messages, index, isNewMessage },
  } = props;

  const { settings, isAllowedOperation } = React.useContext(GenericContext);

  const {
    dateFormats: { dateFormat, timeFormat },
  } = settings;

  const fromMe = !message.agentSide;
  const messageDateTime = message.sentOn ? message.sentOn.format(`${dateFormat} ${timeFormat}`) : '';
  const attachments = message.attachments || [];

  let messageContent = null;
  if (message.randomId !== message.messageId) {
    if (message.ai) {
      messageContent = (
        <AIMessage
          key={message.randomId}
          content={message.content}
          editable={false}
          index={index}
          messages={messages}
          emitSendMessage={emitSendMessage}
          delayScrollDown={delayScrollDown}
          bottomMode={bottomMode}
        />
      );
    } else if (fromMe) {
      messageContent = <span dangerouslySetInnerHTML={{ __html: message.content }} />;
    } else {
      messageContent = message.content;
    }
  }

  const messageOptions = (isNewMessage || isLastMessage) && !fromMe && (message.options || {});
  const loadingClassName = message.randomId === message.messageId ? '' : 'loading-msg';
  const meYouClassName = fromMe ? 'me' : 'you';

  return (
    <div>
      <div className={getClassNames('bubble-wrapper', meYouClassName, loadingClassName)}>
        {!hideSender && (
          <div className="message-header">
            {!fromMe && (
              <span key="participant-name" className="participant-name">
                {get(message, 'sender.name.displayName')}
              </span>
            )}
            <span key="chatsend-time" className="chatsend-time">
              <PopperTooltip placement="top" overlay={messageDateTime}>
                <span>{message.sentOn.format(timeFormat)}</span>
              </PopperTooltip>
              <i
                className={getClassNames(
                  'll-icon ll-done-all pull-right',
                  fromMe ? '' : 'hide',
                  message.readOn ? 'text-info' : 'text-mute',
                )}
                style={{
                  margin: '4px 0 0 5px',
                  fontSize: '14px',
                }}
              />
            </span>
          </div>
        )}
        <div className={getClassNames('dropdown', dropUp(messages, index), 'bubble', meYouClassName)}>
          <div key="bubble-content" className="bubble-content">
            {message.randomId === message.messageId && (
              <span className="outer-loading">
                <SpinnerSmall />
              </span>
            )}
            <Linkify
              // @ts-expect-error
              className="message-content"
              options={{
                target: window.hrefTarget,
              }}
            >
              <div
                style={{
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                }}
              >
                {message.randomId === message.messageId && (
                  <div
                    style={{
                      display: 'inline',
                      marginRight: '7px',
                      padding: 0,
                    }}
                  >
                    {message.content.substr(0, 1) === '/' && message.content.indexOf('#') > -1
                      ? message.content.split('#')[1]
                      : message.content}
                  </div>
                )}
                {messageContent}
              </div>
            </Linkify>
            {!!attachments.length && imagesExtensions.test(message.attachments[0].url) && (
              <img
                src={message.attachments[0].url}
                alt=""
                style={{
                  maxWidth: '100%',
                }}
              />
            )}
            {attachments.map((attachment) => (
              <div key={attachment.url}>
                <a className="attach-file" href={attachment.url} target="_blank" rel="noreferrer">
                  {attachment.name}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      <CustomMessage
        message={message}
        messageOptions={messageOptions}
        emitSendMessage={emitSendMessage}
        isLastMessage={isLastMessage}
        settings={settings}
        isAllowedOperation={isAllowedOperation}
      />
      <div className="clearfix" />
    </div>
  );
}

MessageText.contextTypes = {
  settings: PropTypes.object.isRequired,
};

MessageText.propTypes = {
  hideSender: PropTypes.bool,
  message: PropTypes.object.isRequired,
  topProps: PropTypes.object.isRequired,
  isNewMessage: PropTypes.bool.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default MessageText;
